/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://blog.goorm.io/commit_5th_sketch/"
  }, "썸네일 출처: goorm 블로그")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "단신"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Docker가 무료 org 지원을 중단함에 따라 알아야 할 것들", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "https://news.hada.io/topic?id=8706"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "kube registry 도 리다이렉트 변경?"), "\n", React.createElement(_components.li, null, "meta 해고", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "https://about.fb.com/news/2023/03/mark-zuckerberg-meta-year-of-efficiency/"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "이글루스 종료 소식", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://web.archive.org/web/20091010204933/http://www.textcube.com:80/"
  }, "텍스트큐브")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/"
  }, "Gatsby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.outsider.ne.kr/"
  }, "아웃사이더님 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://web.archive.org/"
  }, "웹 아카이브")), "\n"), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Commit 세미나"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.goorm.io/subicura/"
  }, "사전 인터뷰")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://edu.goorm.io/lecture/37330/re-commit-%EC%84%B1%EA%B3%B5%EC%A0%81%EC%9C%BC%EB%A1%9C-%EC%8B%A0%EA%B8%B0%EC%88%A0%EC%9D%84-%EB%8F%84%EC%9E%85%ED%95%98%EB%8A%94-%EB%B2%95-%EA%B9%80%EC%B6%A9%EC%84%AD-cto"
  }, "강의 영상")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.goorm.io/commit_5th_sketch/"
  }, "현장 스케치")), "\n", React.createElement(_components.li, null, "https://blog.goorm.io/subicura"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.goorm.io/raccoony/"
  }, "예전 너굴님 인터뷰")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
